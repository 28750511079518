import React, { useState, useEffect } from "react";
import { AuthenticationChallenge } from "@Types";
import axios from "axios";

import SigninForm from "@Molecules/common/auth/signinForm";
import GoogleAuthenticatorForm from "@Molecules/common/auth/googleAuthenticatorForm";
import GoogleAuthenticatorImage from "@Molecules/common/auth/googleAuthenticatorImage";
import SmsMFAForm from "@Molecules/common/auth/smsMFAForm";
import GoogleAuthenticationInstructionsCard from "@Molecules/common/auth/googleAuthenticationInstructionsCard";
import MFATypeSelection from "@Molecules/common/auth/mfaTypeSelection";
import NewUserCard from "@Molecules/common/auth/newUserCard";
import Alreadyhaveanaccount from "@Molecules/common/auth/alreadyhaveanaccount";
import EmailMFAComponent from "@Molecules/common/auth/emailMFA";
import { useRouter } from "next/router";
import ForgotPasswordCard from "@Molecules/common/auth/forgotPasswordCard";

const userDefault = {
  attributes: {},
  signInUserSession: {
    accessToken: {
      jwtToken: "",
    },
  },
  username: "",
};

const SigninTemplates = () => {
  const {
    LOGIN,
    MFA_SETUP,
    SMS_MFA,
    SOFTWARE_TOKEN_MFA,
    NEW_PASSWORD_REQUIRED,
    SELECT_MFA_TYPE,
    EMAIL_MFA,
  } = AuthenticationChallenge;
  const router = useRouter();
  const [user, setUser] = useState(userDefault);
  const [challengeName, setChallengeName] = useState(LOGIN);
  const [ip, setIp] = useState("");
  const [code, setCode] = useState("");

  const [timeBaseCode, setTimeBaseCode] = useState("");

  const query: any = router?.query;

  useEffect(() => {
    if (query?.challengeName) {
      setChallengeName(query?.challengeName);
    }
    getUserIPAdress();
  }, [query]);

  const getUserIPAdress = async () => {
    try {
      const { data } = await axios("https://api.ipify.org/?format=json");
      setIp(data?.ip);
    } catch (error) {
      //  Error getting IP");
    }
  };

  switch (challengeName) {
    case MFA_SETUP:
      return (
        <>
          <GoogleAuthenticatorImage
            user={user}
            code={code}
            setCode={setCode}
            timeBaseCode={timeBaseCode}
          />

          <GoogleAuthenticationInstructionsCard />
        </>
      );

    case SOFTWARE_TOKEN_MFA:
      return (
        <>
          <GoogleAuthenticatorForm
            user={user}
            code={code}
            setCode={setCode}
            setChallengeName={setChallengeName}
          />
          <Alreadyhaveanaccount />
        </>
      );

    case NEW_PASSWORD_REQUIRED:
      // TODO: Handle new password
      break;

    case SELECT_MFA_TYPE:
      return <MFATypeSelection user={user} code={code} setCode={setCode} />;

    case SMS_MFA:
      return <SmsMFAForm user={user} code={code} setCode={setCode} />;

    case EMAIL_MFA:
      return <EmailMFAComponent isTwoFactorAuthentication />;

    default:
      return (
        <>
          <SigninForm
            ip={ip}
            setChallengeName={setChallengeName}
            setTimeBaseCode={setTimeBaseCode}
            setUser={setUser}
          />
          <NewUserCard />
          <ForgotPasswordCard />
        </>
      );
  }
};

export default SigninTemplates;
