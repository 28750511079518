import moment from "moment-timezone";

// Function to get user's timezone information
export const getUserTimezoneInfo = () => {
  // Get the current moment
  const now = moment();

  // Get the user's timezone offset in minutes
  const userTimezoneOffset = now.utcOffset();

  // Get the user's timezone name
  const userTimezoneName = moment.tz.guess();

  return { offset: userTimezoneOffset, name: userTimezoneName };
};
