import { LegacyCard, Link } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "next-i18next";

export default function ForgotPasswordCard() {
  const { t } = useTranslation("login");

  return (
    <LegacyCard sectioned>
      <Link url="/forgotpassword">{t("forgot_password_message")}</Link>
    </LegacyCard>
  );
}
