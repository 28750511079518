import { fetchUserToken } from "@Libs";
import { Button, LegacyCard, Form, FormLayout } from "@shopify/polaris";
import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import { AuthenticationChallenge } from "@Types";
import GoogleAuthenticatorCode from "@Molecules/common/auth/googleAuthenticatorCode";
import { useRouter } from "next/router";
import useToast from "../hooks/usetoast";
import useFingerprint from "../hooks/useFingerPrint";

type Props = {
  user: any;
  code: string;
  setCode: (code: string) => void;
  setChallengeName: (challengeName: AuthenticationChallenge) => void;
};

export default function GoogleAuthenticatorForm({
  user,
  code,
  setCode,
  setChallengeName,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fingerprint, device] = useFingerprint();
  const [toast, toggleToast] = useToast();
  const router = useRouter();

  const confirmSignin = async () => {
    try {
      setIsLoading(true);

      const currentUser = await Auth.confirmSignIn(
        user,
        code,
        AuthenticationChallenge.SOFTWARE_TOKEN_MFA
      );
      const token = currentUser?.signInUserSession?.accessToken?.jwtToken;

      await fetchUserToken({ token, fingerprint, device });

      await Auth.rememberDevice();

      toggleToast("User successfully login!");

      router?.push("/user/dashboard");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      if (error?.code === "CodeMismatchException") {
        toggleToast("Invalid code, please enter the correct code", true);
        return;
      }
      toggleToast(error?.message || "Error confirming user", true);

      if (error?.code === "NotAuthorizedException") {
        setChallengeName(AuthenticationChallenge.LOGIN);
      }
    }
  };

  return (
    <LegacyCard sectioned>
      <Form onSubmit={confirmSignin}>
        <FormLayout>
          <GoogleAuthenticatorCode code={code} setCode={setCode} />

          <Button loading={isLoading} variant="primary" submit>
            Submit
          </Button>
        </FormLayout>
        {toast}
      </Form>
    </LegacyCard>
  );
}
