import { TextField } from "@shopify/polaris";
import React, { useCallback } from "react";

import { useTranslation } from "next-i18next";

type Props = {
  code: string;
  setCode: (value: string) => void;
};

export default function GoogleAuthenticatorCode({ code, setCode }: Props) {
  const { t } = useTranslation("auth");
  const hCodeChange = useCallback((value) => setCode(value), []);

  return (
    <>
      <TextField
        value={code}
        onChange={hCodeChange}
        placeholder="Ex: 246534"
        label="Google Authenticator Code"
        type="text"
        helpText={t("google_authenticator_subtitle")}
        autoComplete="text"
      />
    </>
  );
}
