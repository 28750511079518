import { Button, Form } from "@shopify/polaris";
import React, { useState } from "react";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import { useTranslation } from "next-i18next";
import { fetchUserToken } from "@Libs";
import { useRouter } from "next/router";
import { getUserTimezoneInfo } from "@Utils/momentUtils";
import { AuthenticationChallenge } from "@Types";
import useToast from "../hooks/usetoast";
import useFingerprint from "../hooks/useFingerPrint";

type Props = {
  email: string;
  password: string;
  ip: string;
  isNotAllowed: boolean;
  setChallengeName: (challengeName: string) => void;
  setTimeBaseCode: (code: string) => void;
  setUser: (user: any) => void;
};

const {
  NEW_PASSWORD_REQUIRED,
  SMS_MFA,
  SOFTWARE_TOKEN_MFA,
  MFA_SETUP,
  EMAIL_MFA,
} = AuthenticationChallenge;

export default function SignInButton({
  email,
  password,
  ip,
  isNotAllowed,
  setUser,
  setChallengeName,
  setTimeBaseCode,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [fingerprint, device] = useFingerprint();
  const [toast, toggleToast] = useToast();
  const router = useRouter();

  const { t } = useTranslation("login");

  const sendEmailVerification = async (token) => {
    try {
      await axios({
        url: "/api/user/email/twofactor",
        method: "POST",
        data: {
          token,
        },
      });
    } catch (error) {
      // Do nothing
    }
  };

  const signInUser = async () => {
    try {
      if (!email) {
        toggleToast("Please enter email", true);
        return;
      }
      if (!password) {
        toggleToast("Please enter password", true);
        return;
      }

      setIsLoading(true);

      const currentUser = await Auth.signIn(email, password);
      const challengeName = currentUser?.challengeName;

      setUser(currentUser);
      setChallengeName(currentUser?.challengeName);

      // We don't support SMS_MFA flow
      if (challengeName === SMS_MFA || challengeName === SOFTWARE_TOKEN_MFA) {
        setIsLoading(false);
        return;
      }
      if (challengeName === NEW_PASSWORD_REQUIRED) {
        // TODO: Handle new password required for the currentUser

        return;
      }

      if (challengeName === MFA_SETUP) {
        const TOTPCode = await Auth.setupTOTP(currentUser);
        if (TOTPCode) {
          setTimeBaseCode(TOTPCode);
        }
        setIsLoading(false);
        return;
      }

      const token = currentUser?.signInUserSession?.accessToken?.jwtToken;
      const { name } = getUserTimezoneInfo();

      const { data }: any = await axios({
        url: "/api/token/authenticationstatus",
        method: "POST",
        data: {
          token,
          userTimeZone: name,
          ip,
          fingerprint,
          device,
        },
      });

      const isOnboardingCompleted = data?.isOnboardingCompleted;
      const isTwoFactorActivated = data?.isTwoFactorActivated;

      if (!isOnboardingCompleted) {
        setIsLoading(false);
        router.push("/onboarding/stepone");
        return;
      }

      if (!isTwoFactorActivated) {
        await fetchUserToken({ token, fingerprint });
        router.push("/user/dashboard");
        setIsLoading(false);
        return;
      }

      setChallengeName(EMAIL_MFA);
      await sendEmailVerification(token);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const message = error.message || "User unsuccessfully login";
      toggleToast(message, true);
    }
  };

  return (
    <Form onSubmit={signInUser}>
      <Button
        loading={isLoading}
        disabled={isNotAllowed}
        variant="primary"
        submit
      >
        {t("submit_button")}
      </Button>
      {toast}
    </Form>
  );
}
