import { LegacyCard, Link } from "@shopify/polaris";
import React from "react";

import { useTranslation } from "next-i18next";

export default function GoogleAuthenticationInstructionsCard() {
  const { t } = useTranslation("auth");

  return (
    <LegacyCard sectioned>
      <p>{t("google_authenticator_instruction")}</p>
      <Link url="/forgotpassword">Click here</Link>
    </LegacyCard>
  );
}
