import { LegacyCard, Link } from "@shopify/polaris";
import React from "react";
import { useTranslation } from "next-i18next";

export default function NewUserCard() {
  const { t } = useTranslation("login");

  return (
    <div className="mt-2 mb-2">
      <LegacyCard sectioned>
        <p>{t("new_user_message")}</p>
        <Link url="/signup">{t("create_account_message")}</Link>
      </LegacyCard>
    </div>
  );
}
