import { Button, LegacyCard, Frame, Select } from "@shopify/polaris";
import { MFAType } from "@Types";
import Auth from "@aws-amplify/auth";
import { useState, useCallback } from "react";
import SmsCode from "./smsCode";

type Props = {
  user: any;
  code: string;
  setCode: (code: string) => void;
};

export default function MFATypeSelection({ user, code, setCode }: Props) {
  const [mfaType, setMFAType] = useState(MFAType.TOTP);

  const hMFAChange = useCallback((value: MFAType) => setMFAType(value), []);

  const options = [
    { label: "TOTP", value: MFAType.TOTP },
    { label: "SMS", value: MFAType.SMS },
    { label: "NOMFA", value: MFAType.NOMFA },
  ];

  const hSubmit = async () => {
    try {
      user.sendMFASelectionAnswer(mfaType, {
        onFailure: (err) => {
          console.error(err);
        },
        mfaRequired: async (challengeName) => {
          await Auth.confirmSignIn(user, code, challengeName);
        },
        totpRequired: async (challengeName) => {
          await Auth.confirmSignIn(user, code, challengeName);
          // Auth.confirmSignIn with TOTP code
        },
      });
    } catch (error) {
      // TODO: Don't do anything
    }
  };
  return (
    <Frame>
      <LegacyCard sectioned>
        <SmsCode code={code} setCode={setCode} />
      </LegacyCard>
      <LegacyCard sectioned>
        <Select
          label="MFA Type"
          options={options}
          onChange={hMFAChange}
          value={mfaType}
        />{" "}
        <br />
        <Button variant="primary" onClick={hSubmit}>
          Submit
        </Button>
      </LegacyCard>
    </Frame>
  );
}
