import { Button, LegacyCard, Link } from "@shopify/polaris";
import React, { useState } from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "next-i18next";
import { fetchUserToken } from "@Libs";
import { useIsDev } from "@Selectors/userSelector";
import Auth from "@aws-amplify/auth";
import { useRouter } from "next/router";
import useToast from "../hooks/usetoast";
import GoogleAuthenticatorCode from "./googleAuthenticatorCode";
import useFingerprint from "../hooks/useFingerPrint";

type Props = {
  user: any;
  code: string;
  timeBaseCode: string;
  setCode: (code: string) => void;
};

export default function GoogleAuthenticatorImage({
  user,
  code,
  setCode,
  timeBaseCode,
}: Props) {
  const { t } = useTranslation("auth");
  const [toast, toggleToast] = useToast();
  const [fingerprint, device] = useFingerprint();
  const router = useRouter();

  const isDev = useIsDev();

  const [isLoading, setIsLoading] = useState(false);
  const userName = user?.username;
  const issuer = isDev ? "PGecom - Sandbox" : "PGecom";
  const str = `otpauth://totp/AWSCognito:${userName}?secret=${timeBaseCode}&issuer=${issuer}`;

  const confirmToTPToken = async () => {
    try {
      setIsLoading(true);
      await Auth.verifyTotpToken(user, code);
      const token = user?.signInUserSession?.accessToken?.jwtToken;

      Auth.setPreferredMFA(user, "TOTP");
      await fetchUserToken({ token, fingerprint, device });
      await Auth.rememberDevice();
      toggleToast("User successfully login!");

      router?.push("/user/dashboard", "/user/dashboard", {
        locale: "en",
      });
      setIsLoading(false);
    } catch (error) {
      toggleToast(error?.message || "Error confirming the user", true);
      setIsLoading(false);
    }
  };

  return (
    <>
      <LegacyCard title={t("google_authenticator_title")} sectioned>
        <div>
          <QRCode value={str} />
          <br />
        </div>
        <p>
          Download the app{" "}
          <Link url="https://play.google.com/store/search?q=google%20authenticator&c=apps&hl=en_US&gl=US">
            Android
          </Link>{" "}
          or{" "}
          <Link url="https://apps.apple.com/us/app/google-authenticator/id388497605">
            iOS
          </Link>
        </p>
        {toast}
      </LegacyCard>

      <LegacyCard sectioned>
        <GoogleAuthenticatorCode code={code} setCode={setCode} />
      </LegacyCard>

      <LegacyCard sectioned>
        <Button
          loading={isLoading}
          onClick={confirmToTPToken}
          variant="primary"
          submit
        >
          Submit
        </Button>
      </LegacyCard>
    </>
  );
}
